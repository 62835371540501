<template>
  <div class="foot_main" :class="{'dggj': $route.path.includes('dggj')}">
    <div class="foot_cont_info">
      <div class="top_cont_data">
        <p class="logo"><img src="@/assets/pc/logo.png" alt=""><span>全国工匠学院</span></p>
        <div class="cont_menu_data">
          <ul>
            <p class="text">学院介绍</p>
            <li @click="goNextUrl('/collegeInfo')">学院介绍</li>
            <li @click="goNextUrl('/collegeInfo')">政策指导</li>
            <li @click="goNextUrl('/collegeInfo')">领导架构</li>
          </ul>
          <ul>
            <p class="text">学院工作</p>
            <li>工匠学院申报</li>
            <li>工匠学员评估</li>
            <li>工匠学院建设指南</li>
          </ul>
          <ul>
            <p class="text">人才培育</p>
            <li @click="goNextUrl('/rcpyIndex?type=1')">师资</li>
            <li @click="goNextUrl('/rcpyIndex?type=2')">课程</li>
            <li @click="goNextUrl('/rcpyIndex?type=3')">招生</li>
            <li @click="goNextUrl('/rcpyIndex?type=4')">优秀学员</li>
            <li @click="goNextUrl('/rcpyIndex?type=5')">学术科研</li>
          </ul>
          <ul>
            <p class="text">展示交流</p>
            <li @click="showcaseJump('/cxcgIndex')">创新成果</li>
            <li @click="showcaseJump('/cxxmIndex')">创新项目</li>
            <li @click="showcaseJump('/onLine?type=1')">线上直播课</li>
            <li @click="showcaseJump('/onLine?type=2')">线上论坛</li>
          </ul>
        </div>
      </div>
      <div class="cont_list">
        <ul>
          <p class="text">友情链接:</p>
          <div>
            <li><a href="javascript:;" target="_blank" rel="noopener noreferrer">中华全国总工会</a></li>
            <li><a href="javascript:;" target="_blank" rel="noopener noreferrer">中国劳动关系学院劳模学院</a></li>
            <li><a href="javascript:;" target="_blank" rel="noopener noreferrer">中国劳动关系学院劳模学院（工匠学院）创新工作室交流服务平台</a></li>
          </div>
        </ul>
      </div>
      <div class="code_text">Copyright © 2024-现在 全国工匠平台 版权所有   ICP备13017272号-5  公网安备51019002005714</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  data() {
    return {
    };
  },
  props: {
    
  },
  watch: {
    $route: {
      handler(newVal) {
      },
      immediate: true,
    },
  },
  mounted() {
    
  },
  methods: {
    goNextUrl(url){
      this.$router.push({path:url})
    },
    showcaseJump(url) {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));

      if (userInfo && userInfo.identity_type == 2) {
        this.$router.push(url)
      } else {
        this.$message.warning('成为工匠学院学员，即可使用此功能')
      }
    }
  },
};
</script>

<style scoped lang="scss">
.foot_main{
  background: #E2E2E2;
  margin-top: 0 !important;
  .foot_cont_info{
    width: 1200px;
    margin: 0 auto;
    padding: 60px 0 0;
    .top_cont_data{
      display: flex;
      p.logo{
      // width: 199px;
      display: flex;
      align-items: center;
      img{
        width: 58px;
        height: 58px;
      }
      span{
        font-size: 28px;
        color: #3C3C3C;
        margin-left: 15px;
        font-weight: 500;
      }
    }
      .cont_menu_data{
        flex: 1;
        margin-left: 178px;
        display: flex;
        ul{
          margin-right: 93px;
          p.text{
            font-size: 18px;
            color: #333333;
            font-weight: 800;
          }
          li{
            font-size: 14px;
            color: #333333;
            margin-top: 16px;
            cursor: pointer;
          }
        }
        ul:last-child{
          margin-right: 0;
        }
      }

    }
    .cont_list{
      margin-top: 20px;
      border-top: 1px solid #CACACA;
      padding: 20px 0;
      display: flex;
      justify-content: center;
      ul{
        display: flex;
        div{
          margin-left: 10px;
          display: flex;
          flex-wrap: wrap;
          li{
            margin-right: 30px;
          }
          a{
            font-size: 14px;
            color: #0076FF;
            text-decoration: underline;
          }
        }
      }
    }
    .code_text{
      padding-bottom: 30px;
      text-align: center;
      
      font-size: 12px;
      color: #333333;
    }
  }
  &.dggj{
    background-color: #262626;
    .logo{
      margin-bottom: auto;
    }
    .logo span,
    .cont_menu_data li,
    .cont_menu_data .text{
      font-family: 'PingFangSC-Regular';
      color: rgba(255, 255, 255, 0.75) !important;
    }
    .cont_list .text,
    .cont_list a,
    .code_text{
      color: rgba(255, 255, 255, 0.5) !important;
    }
  }
}
</style>
